<template>
  <div class="row">
    <div class="col-12 item">
      <!--    <agile class="thumbnails thumbnailsonly" ref="thumbnails" :options="projects_options">
        <div
          class="slide slide--thumbniail"
          v-for="(project, index) in projects"
          :key="index"
          :class="`slide--${index}`"
          @click="$router.push(projects[index].route)"
        >
          <img :src="project.image" />
        </div>

        <template slot="prevButton">
          <i class="fa fa-chevron-left"></i>
        </template>
        <template slot="nextButton">
          <i class="fa fa-chevron-right"></i>
        </template>
      </agile>-->
    </div>

    <div class="col-12 item">
      <agile class="thumbnails thumbnailsonly" ref="thumbnails" :options="projects_options">
        <div
          class="slide slide--thumbniail"
          v-for="(project, index) in projects"
          :key="index"
          :class="`slide--${index}`"
          @click="$router.push(projects[index].route)"
        >
          <div class="project-col project-mobile-apps">
            <div class="project-item">
              <div class="project-item-card project-item-card-front">
                <img :src="project.image" />
              </div>
              <div class="project-item-card project-item-card-back">
                <div class="project-item-card-center">
                  <h5 class="project-item-title">{{project.title}}</h5>
                  <div class="project-item-desc">
                    <p>{{project.desc}}</p>
                  </div>
                  <div class="wrapp-btn-circl-arrow justify-content-center">
                    <i class="material-icons material-icons-outlined md-48 text-white">chevron_right</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template slot="prevButton">
          <i class="fa fa-chevron-left"></i>
        </template>
        <template slot="nextButton">
          <i class="fa fa-chevron-right"></i>
        </template>
      </agile>
    </div>
  </div>
</template>
<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Projects",
  data() {
    return {
      title: "Projects",
      projects: [
        {
          title: "Peter Goord Travel (Plymouth)",
          desc: "Umbraco Web Development",
          image: "/img/projects/goldentickettravel/image3.png",
          route: "/work/goldentickettravel"
        },
        {
          title: "Havenstar MMS (Wymondham)",
          desc: "Web Portal Vue.js Development",
          image: "/img/projects/havenstar/image1.png",
          route: "/work/havenstar"
        },
        {
          title: "HolidayGems (Stockport)",
          desc: "Cloud Travel Services",
          image: "/img/projects/holidaygems/image1.jpg",
          route: "/work/holidaygems"
        },
        {
          title: "IRIS Payroll Professional (Brighton)",
          desc: "Web Portal Vue.js Development",
          image: "/img/projects/irispayroll/image1.jpg",
          route: "/work/irispayroll"
        },
        {
          title: "Travel Places (Worthing)",
          desc: "Development & Consultancy",
          image: "/img/projects/travelplaces/image1.jpg",
          route: "/work/travelplaces"
        },
        {
          title: "Sunmaster Holidays (Halifax)",
          desc: "Cloud Travel Services",
          image: "/img/projects/sunmaster/themills.jpg",
          route: "/work/sunmaster"
        }
      ],
      projects_options: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              navButtons: true,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
              slidesToShow: 4
            }
          }
        ]
      }
    };
  },
  components: {
    agile: VueAgile
  }
};
</script>


<style>
</style>